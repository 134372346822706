import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
  name: 'FastTime',
  props: {
    start: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    text: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      value2: this.start,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(0, 'day').startOf('day').valueOf());
            var endTime = moment(moment().subtract(0, 'day').endOf('day').valueOf());
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [sta, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(1, 'day').startOf('day').valueOf());
            var endTime = moment(moment().subtract(1, 'day').endOf('day').valueOf());
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [sta, end]);
          }
        }, {
          text: '上周',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(8, 'day').startOf('day').valueOf());
            var endTime = moment(moment().subtract(2, 'day').endOf('day').valueOf());
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [sta, end]);
          }
        }, {
          text: '本周',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(1, 'day').startOf('day').valueOf());
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date();
            picker.$emit('pick', [sta, end]);
          }
        }, {
          text: '上月',
          onClick: function onClick(picker) {
            var startTime = moment().subtract(1, 'month').startOf('month');
            var endTime = moment().subtract(1, 'month').endOf('month');
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [sta, end]);
          }
        }, {
          text: '本月',
          onClick: function onClick(picker) {
            var startTime = moment().startOf('month');
            var start = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '过去三天',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(3, 'day').startOf('day').valueOf());
            var endTime = moment(moment().subtract(1, 'day').endOf('day').valueOf());
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [sta, end]);
          }
        }, {
          text: '过去7天',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(7, 'day').startOf('day').valueOf());
            var endTime = moment(moment().subtract(1, 'day').endOf('day').valueOf());
            var start = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '过去30天',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(30, 'day').startOf('day').valueOf());
            var endTime = moment(moment().subtract(1, 'day').endOf('day').valueOf());
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [sta, end]);
          }
        }, {
          text: '过去90天',
          onClick: function onClick(picker) {
            var startTime = moment(moment().subtract(90, 'day').startOf('day').valueOf());
            var endTime = moment(moment().subtract(1, 'day').endOf('day').valueOf());
            var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
            var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
            picker.$emit('pick', [sta, end]);
          }
        }]
      },
      categorys: this.text
    };
  },
  watch: {},
  mounted: function mounted() {},
  methods: {
    FinalFollowUpEs: function FinalFollowUpEs(val) {
      var time = [];
      time.unshift(val[0].toString().substring(0, 10));
      time.push(val[1].toString().substring(0, 10));
      this.$emit('FinalFollowUpEs', time, this.categorys);
    }
  }
};