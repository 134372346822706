import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import echarts from 'echarts';
// 引入饼图
require('echarts/lib/chart/pie');
// 引入提示框等组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
import { CustomerLevelOverview as _CustomerLevelOverview } from '@/api/article';
export default {
  name: 'Echarts',
  props: {
    startTime: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    NumEs: {
      type: Number,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      myChart: null,
      myChart2: null,
      stime: '',
      etime: '',
      DataScatter: {
        total: '',
        Statistics: []
      },
      DataTrend: {
        years: [],
        adverts_data: [],
        agent_data: [],
        other_data: []
      }
    };
  },
  mounted: function mounted() {},
  watch: {
    startTime: {
      deep: true,
      //深度监听
      handler: function handler(val) {
        var _this = this;
        this.$nextTick(function () {
          if (val[0].toString().length == 10) {
            _this.CustomerLevelOverview(val[0], val[1]);
            _this.stime = val[0];
            _this.etime = val[1];
          } else {
            _this.stime = Date.parse(val[0]).toString().substring(0, 10);
            _this.etime = Date.parse(val[1]).toString().substring(0, 10);
            _this.CustomerLevelOverview(_this.stime, _this.etime);
          }
        });
      }
    },
    NumEs: function NumEs(val) {
      var _this2 = this;
      this.NumEs = val;
      this.$nextTick(function () {
        _this2.CustomerLevelOverview(_this2.stime, _this2.etime);
      });
    }
  },
  methods: {
    CustomerLevelOverview: function CustomerLevelOverview(tiem1, tiem2) {
      var _this3 = this;
      _CustomerLevelOverview({
        stime: tiem1,
        etime: tiem2,
        customer_level_type: this.NumEs.toString()
      }).then(function (res) {
        var years = [];
        var adverts_data = [];
        var agent_data = [];
        var other_data = [];
        _this3.DataScatter.total = res.data.data_scatter.customer_sum.toString();
        res.data.data_scatter.circular_chart.map(function (item) {
          item.value = item.customer_sum;
        });
        _this3.DataScatter.Statistics = res.data.data_scatter.circular_chart;
        res.data.data_trend.adverts_data.forEach(function (element) {
          adverts_data.push(element.num);
          years.push(element.c_create_time);
        });
        _this3.DataTrend.years = years;
        _this3.DataTrend.adverts_data = adverts_data;
        res.data.data_trend.agent_data.forEach(function (element) {
          agent_data.push(element.num);
        });
        _this3.DataTrend.agent_data = agent_data;
        res.data.data_trend.other_data.forEach(function (element) {
          other_data.push(element.num);
        });
        _this3.DataTrend.other_data = other_data;
        _this3.fsdds();
        _this3.dhjsjk();
      });
    },
    fsdds: function fsdds() {
      var chartDom = this.$refs.myChart;
      if (chartDom == undefined || chartDom == null) {
        return;
      }
      this.myChart = this.$echarts.init(chartDom);
      var option = {
        title: {
          text: this.DataScatter.total,
          subtext: '总计',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 26,
            color: '#000'
          },
          // 标题
          subtextStyle: {
            fontWeight: 'normal',
            fontSize: 14,
            color: '#000'
          } // 副标题
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: '5%',
          left: 'center'
        },
        color: ['#1C99FF', '#79C499', '#EFA26C'],
        series: [{
          name: '访问来源',
          type: 'pie',
          radius: ['42%', '50%'],
          // 半径
          center: ['50%', '50%'],
          // 位置
          label: {
            normal: {
              formatter: '{b}\n  {c}'
            }
          },
          data: this.DataScatter.Statistics,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      this.myChart.setOption(option);
    },
    dhjsjk: function dhjsjk() {
      var chartDom = this.$refs.myChart2;
      if (chartDom == undefined || chartDom == null) {
        return;
      }
      this.myChart2 = this.$echarts.init(chartDom);
      var colorList = ['#1C99FF', '#79C499', '#EFA26C'];
      var option = {
        backgroundColor: '#fff',
        title: {
          text: '数据趋势',
          textStyle: {
            fontSize: 22,
            fontWeight: 400
          },
          left: 'center',
          top: '5%'
        },
        legend: {
          icon: 'circle',
          top: '5%',
          right: '5%',
          itemWidth: 6,
          itemGap: 20,
          textStyle: {
            color: '#556677'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
        },
        grid: {
          top: '15%'
        },
        xAxis: [{
          type: 'category',
          data: this.DataTrend.years,
          axisLine: {
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 3,
            textStyle: {
              color: '#556677'
            },
            showMaxLabel: true,
            // 默认x轴字体大小
            fontSize: 12,
            // margin:文字到x轴的距离
            margin: 15
          },
          axisPointer: {
            label: {
              // padding: [11, 5, 7],
              padding: [0, 0, 10, 0],
              // 这里的margin和axisLabel的margin要一致!
              margin: 15,
              // 移入时的字体大小
              fontSize: 12,
              backgroundColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#fff' // 0% 处的颜色
                }, {
                  offset: 0.86,
                  color: '#fff' // 0% 处的颜色
                }, {
                  offset: 0.86,
                  color: '#33c0cd' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#33c0cd' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            }
          },
          boundaryGap: false
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#556677'
            }
          },
          splitLine: {
            show: false
          }
        }, {
          type: 'value',
          position: 'right',
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#556677'
            },
            formatter: '{value}'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          splitLine: {
            show: false
          }
        }],
        series: [{
          name: '付费广告',
          type: 'line',
          data: this.DataTrend.adverts_data,
          symbolSize: 5,
          symbol: 'circle',
          smooth: true,
          yAxisIndex: 0,
          showSymbol: true,
          lineStyle: {
            width: 5,
            color: '#1C99FF',
            shadowColor: 'rgba(158,135,255, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[0],
              borderColor: colorList[0]
            }
          }
        }, {
          name: '代理推送',
          type: 'line',
          data: this.DataTrend.agent_data,
          symbolSize: 5,
          symbol: 'circle',
          smooth: true,
          yAxisIndex: 0,
          showSymbol: true,
          lineStyle: {
            width: 5,
            color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#73DD39'
            }, {
              offset: 1,
              color: '#73DDFF'
            }]),
            shadowColor: 'rgba(115,221,255, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[1],
              borderColor: colorList[1]
            }
          }
        }, {
          name: '其他',
          type: 'line',
          data: this.DataTrend.other_data,
          symbolSize: 5,
          yAxisIndex: 0,
          symbol: 'circle',
          smooth: true,
          showSymbol: true,
          lineStyle: {
            width: 5,
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
              offset: 0,
              color: '#fe9a'
            }, {
              offset: 1,
              color: '#fe9a8b'
            }]),
            shadowColor: 'rgba(254,154,139, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[2],
              borderColor: colorList[2]
            }
          }
        }]
      };
      this.myChart2.setOption(option);
    }
  }
};