var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CardAssemblys" }, [
    _c(
      "ul",
      _vm._l(_vm.CardArrs, function (item, index) {
        return _c("li", { key: index }, [
          _c("div", { staticClass: "CardAssemblyTop" }, [
            _c(
              "span",
              [
                _vm._v(_vm._s(item.name)),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: item.prompt,
                      placement: "top-start",
                    },
                  },
                  [_c("i", { staticClass: "el-icon-question" })]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(item.num))]),
            _vm._v(" "),
            _c("img", { attrs: { src: item.style, alt: "" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "CardAssemblyBottom", style: item.color }, [
            _c("span", [
              _vm._v("环比：" + _vm._s(item.RingRatio.replace("-", ""))),
            ]),
            _vm._v(" "),
            item.RingRatio.indexOf("-") != -1
              ? _c("i", { staticClass: "iconfont icon-arrdown" })
              : _c("i", { staticClass: "iconfont icon-arrup" }),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }