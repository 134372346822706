//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Head',
  props: {
    name: {
      type: String,
      default: 'font-awesome'
    }
  },
  data: function data() {
    return {
      tiemS: ''
    };
  },
  mounted: function mounted() {
    this.addDate();
  },
  methods: {
    addDate: function addDate() {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };
      var newmonth = date.month >= 10 ? date.month : '0' + date.month;
      var day = date.date >= 10 ? date.date : '0' + date.date;
      this.tiemS = date.year + '-' + newmonth + '-' + day;
    }
  }
};