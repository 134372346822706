import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import Head from '@/components/head/index2';
import Heads from '@/components/head/index3';
import FastTime from '@/components/FastTime/index';
import CardAssembly from '@/components/CardAssembly/index';
import AnalysisTable from '@/components/AnalysisTable/index';
import CardAssemblys from '@/components/CardAssembly/index2';
import ProgressDar from '@/components/CardAssembly/ProgressDar';
import Echarts from '@/components/CardAssembly/Echarts';
// '../../assets/images/top_images/login.png'
import indicators from "../../assets/saleimg/indicators.png";
import indicators2 from "../../assets/saleimg/indicators2.png";
import indicators3 from "../../assets/saleimg/indicators3.png";
import indicators4 from "../../assets/saleimg/indicators4.png";
import Statistics1 from "../../assets/saleimg/Statistics1.png";
import Statistics2 from "../../assets/saleimg/Statistics2.png";
import Statistics3 from "../../assets/saleimg/Statistics3.png";
import Statistics4 from "../../assets/saleimg/Statistics4.png";
import ColorDlock from "../../assets/saleimg/ColorDlock.png";
import { customerStatistics, PayStatistics, IncomeStatistics, LustomerLevelOverview, getListByTree, CustomerLevelOverview } from '@/api/article';
import { log } from 'video.js';
export default {
  name: 'extensionProject',
  data: function data() {
    return {
      num: 0,
      title: '销售看板',
      title1: '数据指标分析',
      title2: '数据概览',
      title3: '数据详情分析',
      startTime: [],
      startTime2: [],
      startTime3: [],
      startTime4: [],
      startTime5: [],
      startTime6: [],
      categorys: {
        pay: 'pay',
        income: 'income'
      },
      DataScatter: {
        total: '',
        Statistics: []
      },
      DataTrend: {
        years: [],
        adverts_data: [],
        agent_data: [],
        other_data: []
      },
      arr: ['总客户数', '有效数量', '重点意向数量', '缴费数量'],
      CardArr: [{
        style: "background: url('" + indicators + "') no-repeat;",
        icon: 'iconfont icon-kehu',
        num: '',
        name: '',
        RingRatio: '',
        prompt: '入库客户数量'
      }, {
        style: "background: url('" + indicators2 + "') no-repeat;",
        icon: 'iconfont icon-kehugenjin',
        num: '',
        name: '',
        RingRatio: '',
        prompt: '入库客户跟进次数'
      }, {
        style: "background: url('" + indicators3 + "') no-repeat;",
        icon: 'iconfont icon-kehugenjin',
        num: '',
        name: '',
        RingRatio: '',
        prompt: '入库客户在3天之内进行跟进的客户数量'
      }, {
        style: "background: url('" + indicators4 + "') no-repeat;",
        icon: 'iconfont icon-kehu',
        num: '',
        name: '',
        RingRatio: '',
        prompt: '客户级别为（一般意向+重点意向+长期跟进+缴费+已收表+已面试+已发通知书+收表不读+发通知书不读+报读条件不足+其他专业需求+其他项目需求+不接受出国+暂无支付能力+暂不接收异地+暂无计划）为有效客户'
      }],
      CardArrs: [{
        name: '',
        num: '',
        style: Statistics1,
        color: 'color:#6F71F0;',
        RingRatio: '',
        prompt: '缴费的客户数量'
      }, {
        name: '',
        num: '',
        style: Statistics2,
        color: 'color:#EFA36C;',
        RingRatio: '',
        prompt: '时间在前30天之内创建且缴费的数量'
      }, {
        name: '',
        num: '',
        style: Statistics3,
        color: 'color:#79C499;',
        RingRatio: '',
        prompt: '时间在前30天之外创建且缴费的数量'
      }, {
        name: '',
        num: '',
        style: Statistics4,
        color: 'color:#4A96F7;',
        RingRatio: '',
        prompt: '缴费数/部门人员'
      }],
      CardArrs2: [{
        name: '',
        num: '',
        style: Statistics1,
        color: 'color:#6F71F0;',
        RingRatio: '',
        prompt: '总缴费金额'
      }, {
        name: '',
        num: '',
        style: Statistics2,
        color: 'color:#EFA36C;',
        RingRatio: '',
        prompt: '时间在前30天之内创建且缴费的金额'
      }, {
        name: '',
        num: '',
        style: Statistics3,
        color: 'color:#79C499;',
        RingRatio: '',
        prompt: '时间在前30天之外创建且缴费的金额'
      }, {
        name: '',
        num: '',
        style: Statistics4,
        color: 'color:#4A96F7;',
        RingRatio: '',
        prompt: '收入/缴费数'
      }],
      progress: {
        title: '数据总量',
        padding: 'padding-left: 60px;padding-right: 60px;',
        conData: [{
          category: '总客户数',
          peoplenum: '0',
          degreenum: 0,
          color: '#6F71F0',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '有效数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#1A95FF',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '重点意向数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#EFA26C',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '缴费数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#79C499',
          display: 'display: none;',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }]
      },
      progresse: {
        title: '付费广告',
        padding: 'padding-left: 40px;padding-right: 30px;',
        conData: [{
          category: '总客户数',
          peoplenum: '0',
          degreenum: 0,
          color: '#6F71F0',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '有效数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#1A95FF',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '重点意向数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#EFA26C',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '缴费数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#79C499',
          display: 'display: none;',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }]
      },
      progressa: {
        title: '代理推送',
        padding: 'padding-left: 30px;padding-right: 30px;',
        conData: [{
          category: '总客户数',
          peoplenum: '0',
          degreenum: 0,
          color: '#6F71F0',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '有效数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#1A95FF',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '重点意向数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#EFA26C',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '缴费数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#79C499',
          display: 'display: none;',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }]
      },
      progressn: {
        title: '其他数据',
        padding: 'padding-left: 30px;padding-right: 40px;',
        conData: [{
          category: '总客户数',
          peoplenum: '0',
          degreenum: 0,
          color: '#6F71F0',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '有效数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#1A95FF',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '重点意向数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#EFA26C',
          display: '',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }, {
          category: '缴费数量',
          peoplenum: '0',
          degreenum: 0,
          color: '#79C499',
          display: 'display: none;',
          back: "background: url('" + ColorDlock + "') no-repeat;",
          contrast: '0%'
        }]
      },
      col: [],
      data: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // 默认时间
    this.DefaultTime();
    this.IndexAnalysis();
    this.TotalData();
    this.tableEvent();
  },
  components: {
    Head: Head,
    Heads: Heads,
    Echarts: Echarts,
    FastTime: FastTime,
    ProgressDar: ProgressDar,
    CardAssembly: CardAssembly,
    CardAssemblys: CardAssemblys,
    AnalysisTable: AnalysisTable
  },
  methods: {
    changeCurrent: function changeCurrent(index) {
      this.num = index;
    },
    DefaultTime: function DefaultTime() {
      // 昨天
      var startTime = moment(moment().subtract(1, 'day').startOf('day').valueOf());
      var endTime = moment(moment().subtract(1, 'day').endOf('day').valueOf());
      var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00').replace(/-/g, "/"));
      var end = new Date(endTime.format('YYYY-MM-DD 23:59:59').replace(/-/g, "/"));
      this.startTime.push(sta);
      this.startTime.push(end);
      // 本月
      var startTime2 = moment().startOf('month');
      var sta2 = new Date(startTime2.format('YYYY-MM-DD 00:00:00').replace(/-/g, "/"));
      var end2 = new Date();
      this.startTime2.push(sta2);
      this.startTime2.push(end2);
      this.startTime3.push(sta2);
      this.startTime3.push(end2);
      this.startTime4.push(sta2);
      this.startTime4.push(end2);
      this.startTime5.push(sta2);
      this.startTime5.push(end2);
      this.startTime6.push(sta2);
      this.startTime6.push(end2);
    },
    FinalFollowUpEs: function FinalFollowUpEs(time) {
      var _this = this;
      this.startTime2 = time;
      customerStatistics({
        stime: time[0],
        etime: time[1]
      }).then(function (res, index) {
        _this.CardArr.map(function (item, index) {
          res.data.forEach(function (event, key) {
            if (index == key) {
              item.num = event.sum;
              item.name = event.name;
              item.RingRatio = event.qoq;
            }
          });
        });
      });
    },
    FinalFollowUp4: function FinalFollowUp4(time) {
      var _this2 = this;
      this.startTime4 = time;
      LustomerLevelOverview({
        stime: time[0],
        etime: time[1]
      }).then(function (res) {
        _this2.progress.conData.map(function (item, index) {
          res.data.sum.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
        _this2.progresse.conData.map(function (item, index) {
          res.data.adverts.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
        _this2.progressa.conData.map(function (item, index) {
          res.data.agent.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
        _this2.progressn.conData.map(function (item, index) {
          res.data.other.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
      });
    },
    FinalFollowUp5: function FinalFollowUp5(time) {
      this.startTime5 = time;
    },
    FinalFollowUp6: function FinalFollowUp6(time) {
      var _this3 = this;
      this.startTime6 = time;
      getListByTree({
        sdate: time[0],
        edate: time[1]
      }).then(function (res) {
        _this3.col = res.data.col;
        _this3.data = res.data.data;
      });
    },
    FinalFollowUp: function FinalFollowUp(time, text) {
      var _this4 = this;
      if (text == 'pay') {
        PayStatistics({
          stime: time[0],
          etime: time[1]
        }).then(function (res) {
          _this4.CardArrs.map(function (item, index) {
            res.data.forEach(function (event, key) {
              if (index == key) {
                item.num = event.sum;
                item.name = event.name;
                item.RingRatio = event.qoq;
              }
            });
          });
        });
      } else if (text == 'income') {
        IncomeStatistics({
          stime: time[0],
          etime: time[1]
        }).then(function (res) {
          _this4.CardArrs2.map(function (item, index) {
            res.data.forEach(function (event, key) {
              if (index == key) {
                item.num = event.sum;
                item.name = event.name;
                item.RingRatio = event.qoq;
              }
            });
          });
        });
      }
    },
    IndexAnalysis: function IndexAnalysis() {
      var _this5 = this;
      var stime = Date.parse(this.startTime[0]).toString().substring(0, 10);
      var etime = Date.parse(this.startTime[1]).toString().substring(0, 10);
      customerStatistics({
        stime: stime,
        etime: etime
      }).then(function (res, index) {
        _this5.CardArr.map(function (item, index) {
          res.data.forEach(function (event, key) {
            if (index == key) {
              item.num = event.sum;
              item.name = event.name;
              item.RingRatio = event.qoq;
            }
          });
        });
      });
      var stime2 = Date.parse(this.startTime2[0]).toString().substring(0, 10);
      var etime2 = Date.parse(this.startTime2[1]).toString().substring(0, 10);
      PayStatistics({
        stime: stime2,
        etime: etime2
      }).then(function (res) {
        _this5.CardArrs.map(function (item, index) {
          res.data.forEach(function (event, key) {
            if (index == key) {
              item.num = event.sum;
              item.name = event.name;
              item.RingRatio = event.qoq;
            }
          });
        });
      });
      IncomeStatistics({
        stime: stime2,
        etime: etime2
      }).then(function (res) {
        _this5.CardArrs2.map(function (item, index) {
          res.data.forEach(function (event, key) {
            if (index == key) {
              item.num = event.sum;
              item.name = event.name;
              item.RingRatio = event.qoq;
            }
          });
        });
      });
    },
    TotalData: function TotalData() {
      var _this6 = this;
      var stime = Date.parse(this.startTime4[0]).toString().substring(0, 10);
      var etime = Date.parse(this.startTime4[1]).toString().substring(0, 10);
      LustomerLevelOverview({
        stime: stime,
        etime: etime
      }).then(function (res) {
        _this6.progress.conData.map(function (item, index) {
          res.data.sum.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
        _this6.progresse.conData.map(function (item, index) {
          res.data.adverts.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
        _this6.progressa.conData.map(function (item, index) {
          res.data.agent.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
        _this6.progressn.conData.map(function (item, index) {
          res.data.other.forEach(function (event, key) {
            if (index == key) {
              item.peoplenum = event.customer_sum;
              item.degreenum = parseInt(event.rate);
              item.contrast = event.conversion_rate;
            }
          });
        });
      });
    },
    tableEvent: function tableEvent() {
      var _this7 = this;
      var stime = Date.parse(this.startTime4[0]).toString().substring(0, 10);
      var etime = Date.parse(this.startTime4[1]).toString().substring(0, 10);
      getListByTree({
        sdate: stime,
        edate: etime
      }).then(function (res) {
        _this7.col = res.data.col;
        _this7.data = res.data.data;
      });
    }
  }
};