//
//
//
//
//
//
//
//

import MyColumn from "./MyColumn";
export default {
  components: {
    MyColumn: MyColumn
  },
  props: {
    col: {
      type: Array
    },
    data: {
      type: Array
    }
  }
};