//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProgressDar',
  props: {
    progress: {
      type: Object,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {}
};