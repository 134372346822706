var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "echarts" },
    [
      _c(
        "el-row",
        { staticStyle: { padding: "20px" }, attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { position: "relative" },
              attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 11 },
            },
            [
              _c("p", { staticClass: "titleData" }, [_vm._v("数据分布")]),
              _vm._v(" "),
              _c("div", {
                ref: "myChart",
                staticStyle: {
                  width: "100%",
                  height: "500px",
                  margin: "0 auto",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 11 } }, [
            _c("div", {
              ref: "myChart2",
              staticStyle: { width: "100%", height: "500px" },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }