//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MyColumn',
  props: {
    col: {
      type: Object
    },
    align: {
      type: String
    }
  }
};