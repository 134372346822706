var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table-column",
    {
      attrs: {
        prop: _vm.col.prop,
        label: _vm.col.label,
        width: _vm.col.width,
        align: _vm.align,
      },
    },
    [
      _vm.col.children
        ? _vm._l(_vm.col.children, function (item, index) {
            return _c("my-column", {
              key: index,
              attrs: { align: "center", col: item },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }