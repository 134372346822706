var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress" },
    [
      _c(
        "el-row",
        { style: _vm.progress.padding, attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } }, [
            _c("p", [_vm._v(_vm._s(_vm.progress.title))]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.progress.conData, function (item, index) {
            return _c(
              "el-col",
              { key: index, attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
              [
                _c("div", { staticClass: "ProgressTitle" }, [
                  _c("div", [_vm._v(_vm._s(index + 1))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(item.category))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(item.peoplenum) + "人")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        "stroke-width": 26,
                        percentage: item.degreenum,
                        color: item.color,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "proportion", style: item.display }, [
                  _c(
                    "div",
                    { staticClass: "proportionNum", style: item.back },
                    [_vm._v(_vm._s(item.contrast))]
                  ),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }