var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CardAssembly" }, [
    _c(
      "ul",
      _vm._l(_vm.CardArr, function (item, index) {
        return _c("li", { key: index, style: item.style }, [
          _c("div", { staticClass: "CardTop" }, [
            _c("div", { staticClass: "icone" }, [
              _c("i", { class: item.icon }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "CardData" }, [
              _c(
                "div",
                [
                  _vm._v(_vm._s(item.name) + " "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: item.prompt,
                        placement: "top-start",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(item.num))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "CardBottom" }, [
            _c("span", [
              _vm._v("环比：" + _vm._s(item.RingRatio.replace("-", ""))),
            ]),
            _vm._v(" "),
            item.RingRatio.indexOf("-") != -1
              ? _c("i", { staticClass: "iconfont icon-arrdown" })
              : _c("i", { staticClass: "iconfont icon-arrup" }),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }