var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container SalesKanban" }, [
    _c(
      "div",
      [
        _c(
          "el-header",
          {
            staticStyle: {
              height: "auto",
              padding: "0 12px",
              background: "rgb(250, 250, 250)",
            },
          },
          [
            _c(
              "el-row",
              {
                staticStyle: { "border-bottom": "26px solid #fafafa" },
                attrs: { gutter: 24 },
              },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                  [_c("Heads", { attrs: { name: _vm.title } })],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "el-row",
              { staticStyle: { padding: "20px" }, attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                  [_c("Head", { attrs: { name: _vm.title1 } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                  },
                  [
                    _c("fast-time", {
                      attrs: { start: _vm.startTime, text: "" },
                      on: { FinalFollowUpEs: _vm.FinalFollowUpEs },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { display: "inline-flex" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                  },
                  [_c("card-assembly", { attrs: { CardArr: _vm.CardArr } })],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container2",
            staticStyle: {
              "border-bottom": "20px solid #fafafa",
              "border-top": "20px solid #fafafa",
            },
          },
          [
            _c(
              "div",
              { staticClass: "ContainerData" },
              [
                _c(
                  "el-row",
                  { staticStyle: { padding: "20px" }, attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-left": "7px" },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                      },
                      [
                        _c("fast-time", {
                          attrs: {
                            start: _vm.startTime2,
                            text: _vm.categorys.pay,
                          },
                          on: { FinalFollowUpEs: _vm.FinalFollowUp },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { display: "inline-flex" },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                      },
                      [
                        _c("card-assemblys", {
                          attrs: { CardArrs: _vm.CardArrs },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ContainerData" },
              [
                _c(
                  "el-row",
                  { staticStyle: { padding: "20px" }, attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-left": "7px" },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                      },
                      [
                        _c("fast-time", {
                          attrs: {
                            start: _vm.startTime3,
                            text: _vm.categorys.income,
                          },
                          on: { FinalFollowUpEs: _vm.FinalFollowUp },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { display: "inline-flex" },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                      },
                      [
                        _c("card-assemblys", {
                          attrs: { CardArrs: _vm.CardArrs2 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "el-row",
              { staticStyle: { padding: "20px" }, attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                  [_c("Head", { attrs: { name: _vm.title2 } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                  },
                  [
                    _c("fast-time", {
                      attrs: { start: _vm.startTime4, text: "" },
                      on: { FinalFollowUpEs: _vm.FinalFollowUp4 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { display: "inline-flex" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                  },
                  [_c("progress-dar", { attrs: { progress: _vm.progress } })],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "PaidAdvertising" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "paddings",
                    attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { display: "inline-flex" },
                            attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                          },
                          [
                            _c("progress-dar", {
                              attrs: { progress: _vm.progresse },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticClass: "contert paddings",
                    attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { display: "inline-flex" },
                            attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                          },
                          [
                            _c("progress-dar", {
                              attrs: { progress: _vm.progressa },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticClass: "paddings",
                    attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { display: "inline-flex" },
                            attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                          },
                          [
                            _c("progress-dar", {
                              attrs: { progress: _vm.progressn },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "Statistics" },
          [
            _c(
              "el-row",
              { staticStyle: { padding: "20px" }, attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                  [_c("Head", { attrs: { name: _vm.title3 } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "margin-top": "20px", position: "relative" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                  },
                  [
                    _c("fast-time", {
                      staticStyle: { width: "100%" },
                      attrs: { start: _vm.startTime5, text: "" },
                      on: { FinalFollowUpEs: _vm.FinalFollowUp5 },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "categoryBox" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.arr, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "categoryTab",
                              class: { current: _vm.num == index },
                              on: {
                                click: function ($event) {
                                  return _vm.changeCurrent(index)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                  [
                    _c("Echarts", {
                      attrs: { startTime: _vm.startTime5, NumEs: _vm.num },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "AnalysisTable" },
          [
            _c(
              "el-row",
              { staticStyle: { padding: "20px" }, attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { "margin-top": "20px", position: "relative" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                  },
                  [
                    _c("fast-time", {
                      staticStyle: { width: "100%" },
                      attrs: { start: _vm.startTime6, text: "" },
                      on: { FinalFollowUpEs: _vm.FinalFollowUp6 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("analysis-table", { attrs: { col: _vm.col, data: _vm.data } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }