var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-table" },
    [
      _c(
        "el-table",
        { attrs: { "row-key": "id", data: _vm.data } },
        _vm._l(_vm.col, function (item, index) {
          return _c("my-column", {
            key: index,
            attrs: { col: item, align: index == 0 ? "left" : "center" },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }